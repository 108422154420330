.header-nav {

  .logo-wrap {
    height: 55px;
    @include media-breakpoint-up(md) {
      height: auto;
    }
  }
  .nav-top {
    color: $black2;
    border-bottom: solid 1px #e4e4e4;
    position: relative;
    z-index: 1001;
  }

  @include media-breakpoint-up(md) {
    padding: 0 0 10px;
  }

  p {
    margin-bottom: 0;
    font-size: 10px;
  }

  .sos-med {
    padding-right: 15px;
    padding-left: 5px;
    border-left: solid 1px #e4e4e4;
    margin-left: 15px;

    a {
      color: $grey1;
      margin-left: 10px;

      @include media-breakpoint-up(md) {
        margin-left: 15px;
      }

      &:hover {
        color: $blue;
      }
    }
  }

  .nav-brand {
    position: relative;
    transition: all ease-in-out 0.3s;
    width: 100%;
    z-index: 1001;
    background: $white;
    transform: translateY(-5px);
    padding-bottom: 0;

    @include media-breakpoint-up(md) {
      padding-bottom: 0;
    }

    .navbar-brand {
      padding: 0;

      img {
        width: 180px;

        @include media-breakpoint-up(md) {
          width: 240px;
        }
      }

      @include media-breakpoint-up(lg) {
        margin-right: 20px;
      }
    }

    .navbar-toggler {
      color: $blue;

      .fa-times {
        display: none;
        font-size: 26px;
      }

      &.open {
        .fa-times {
          display: block;
        }

        .fa-bars {
          display: none;
        }
      }
    }

    form {
      position: relative;

      input {
        border-radius: 20px;
        border: solid 1px #d8d8d8;
        font-size: 14px;
        height: 38px;
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
        background: #e2e2e2;
        border: none;
        border-radius: 100%;
        color: $blue;
        font-size: 15px;
        height: 38px;
      }
    }

    .social {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;

      .wa-icon {
        font-size: 12px;
        text-align: left;
        color: $black2;
        position: relative;
        bottom: -5px;

        strong {
          display: block;
        }

        @include media-breakpoint-up(lg) {
          display: inline-flex;
          width: 125px;
          bottom: -5px;

          i {
            margin-right: 5px;
          }
        }

      }

      a,
      .btn-header {
        border: none;
        background: transparent;
        margin: 0 0 0 10px;
        position: relative;

        @include media-breakpoint-up(xl) {
          margin: 0 0 0 20px;
        }

        .img-icon {
          width: 26px;

          @include media-breakpoint-up(md) {
            width: 30px;
          }

        }

        .tag-label {
          background-color: $yellow1;
          border-radius: 100%;
          width: 15px;
          height: 15px;
          font-size: 11px;
          color: $blue;
          position: absolute;
          right: -4px;
          text-align: center;
          line-height: 15px;
          top: -7px;

          @include media-breakpoint-up(md) {
            width: 20px;
            height: 20px;
            line-height: 20px;
            top: -10px;
            font-size: 12px;
          }
        }
      }

      i {
        font-size: 25px;
        color: $blue;

        @include media-breakpoint-up(lg) {
          font-size: 34px;
        }
      }
    }
  }

  .nav-item.icon-img {
    transition: all ease-in-out 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  &.fix {
    .nav-brand {
      transform: translateY(0);
      position: fixed;
      top: 0;
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      border-top: solid 1px #e4e4e4;

      @include media-breakpoint-up(md) {
        position: relative;
      }
    }

    .nav-main {
      position: fixed;
      top: 57px;

      @include media-breakpoint-up(md) {
        transform: translateY(0);
        position: fixed;
        top: 0;
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

        .navbar-nav .nav-item .sub-menu {
          top: 75px;
        }

        .popup-notif {
          top: 57px;
        }
      }

      @include media-breakpoint-up(lg) {
        .navbar-nav {
          .navbar-brand {
            width: auto;
            opacity: 1;
            padding: 0;
          }

          .nav-item {
            &.icon-img {
              width: auto;
              visibility: visible;
              opacity: 1;

            }
          }
        }
      }
    }

    /*.popup-notif {
      position: fixed;
    }*/
  }

  .nav-main {
    background: $blue;
    transition: all ease-in-out 0.3s;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
    transform: translateX(-100%);
    position: fixed;
    height: 100%;
    top: 75px;

    @include media-breakpoint-up(md) {
      transform: translateX(0);
      height: auto;
      top: initial;
      position: relative;
    }
    &:before {
      content: '';
      position: absolute;
      background: $yellow1;
      height: 3px;
      width: 100%;
      bottom: 3px;
    }

    &.open {
      transform: translateX(0);

      .navbar-collapse {
        display: block;
      }
    }


    .navbar {
      padding-left: 0;
      padding-right: 0;
    }

    .navbar-nav {
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;

      @include media-breakpoint-up(md) {
        align-items: center;
      }


      .navbar-brand {
        margin: 0;
        width: 0;
        opacity: 0;
      }
      .nav-item {

        &.icon-img {
          transition: all ease-in-out 0.3s;
          visibility: hidden;
          opacity: 0;
          width: 0;

          @include media-breakpoint-up(lg) {
            a {
              padding: 0 10px;
            }
          }

          img {
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
          }
        }

        .nav-link {
          text-align: left;
          color: $white;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 13px;
          cursor: pointer;
          padding: 10px;

          &:hover {
            color: $yellow1;
          }

          @include media-breakpoint-up(md) {
            padding: 5px 3px;
          }
          @include media-breakpoint-up(lg) {
            padding: 8px 8px;
            font-size: 13px;
          }

          @include media-breakpoint-up(xl) {
            font-size: 15px;
            padding: 12px 15px;
          }

          i {
            font-size: 12px;
            margin-left: 5px;

          }
        }

        &:hover {
          >.sub-menu {
            padding: 0;
            height: auto;
            visibility: visible;
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);

            @include media-breakpoint-up(md) {
              padding: 0;
            }
          }
        }

        .sub-menu {
          transition: transform 0.2s ease-out;
          padding: 0;
          -webkit-transform: translate3d(0, -5px, 0);
          transform: translate3d(0, -5px, 0);
          will-change: transform, top;

          @include media-breakpoint-up(md) {
            height: 0;
            visibility: hidden;
            opacity: 0;
            position: absolute;
            background-color: $yellow1;
            min-width: 160px;
            top: 58px;
          }

          @include media-breakpoint-up(lg) {
            top: 50px;
          }

          @include media-breakpoint-up(xl) {
            top: 56px;
          }

          .nav-item {
            list-style: none;
            border-bottom: 1px solid $blue;

            &:hover {
              .nav-link {
                color: $white;
              }
            }

            .nav-link {
              padding: 8px 25px;
              font-size: 13px;

              @include media-breakpoint-up(md) {
                color: $blue;
                font-weight: 700;
                padding: 10px 15px;
                text-align: left;
                font-size: 12px;
              }

              @include media-breakpoint-up(lg) {
                padding: 10px 25px;
              }
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }

      .social {
        a,
        .btn-header {
          position: relative;
          border: none;
          background: transparent;

          .tag-label {
            background-color: $yellow1;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            font-size: 12px;
            color: $blue;
            position: absolute;
            right: -2px;
            text-align: center;
            line-height: 20px;
            top: -10px;
          }
        }
      }
    }

    .navbar {
      &.open {
        .navbar-collapse {
          display: block;
        }
      }
    }
  }

  .btn-group-header {
    &:hover {
      .popup-notif {
        height: auto;
        visibility: visible;
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .popup-notif {
    position: absolute;
    background-color: $white;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.19);
    z-index: 10001;
    right: 0;
    top: 45px;
    width: 300px;
    padding: 15px;
    transition: transform 0.2s ease-out;
    height: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
    will-change: transform, top;

    @include media-breakpoint-up(md) {
      top: 42px;
    }

    &.notif {
      max-height: 400px;
      overflow-y: auto;
    }

    &.menu {
      width: 150px;

      .item-review {
        text-align: left;

        a, button {
          font-weight: 700;
          margin: 0;
          padding: 5px 10px;
          font-size: 14px;

          &:hover {
            text-decoration: none;
            color: $yellow;
          }
        }

      }
    }

    .product-list {
      border-bottom: 1px solid rgba(0, 0, 0, 0.19);

      time {
        font-size: 10px;
      }

      .item-review {
        display: flex;
        margin-bottom: 10px;

        .img-wrap {
          width: 60px;
        }

        .content {
          width: calc(100% - 60px);
          padding-left: 10px;
          padding-right: 10px;

          h3 {
            font-size: 12px;
            font-weight: 700;
          }

          p {
            font-size: 10px;
          }
        }

        .btn-delete {
          color: $red;
          border-radius: 0;

          i {
            color: $red;
            font-size: 12px;
          }
        }
      }

      &.notif {
        .item-review {
          padding-top: 10px;
          border-top: 1px solid rgba(0, 0, 0, 0.19);

          &:first-child {
            border-top: none;
          }
        }
      }
    }

    .total {
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.19);;
      margin-bottom: 15px;

      p {
        font-size: 16px;
        font-weight: 700;
      }
    }

    button.MuiButton-root {
      padding: 10px 40px;
      font-size: 14px;
    }

    &.open {
      height: auto;
      visibility: visible;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

  }

  .modal-overlay {
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: block;

    &.closed {
      display: none;
      z-index: 1000;
    }
  }

  .float-wa {
    position: fixed;
    right: 15px;
    bottom: 20px;
    background: #128C7E;
    color: $white;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 30px;
    display: block;
    transition: all ease-in-out 0.3s;
    z-index: 100;

    &:hover {
      background: #075E54;
    }
  }
}
.footer-info {
  background: $blue;
  color: $white;

  .top {
    padding-bottom: 30px;
    padding-top: 30px;
    font-size: 15px;

    .info {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        background: $yellow1;
        height: 10px;
        width: 100%;
        top: -30px;
      }
    }

    h3 {
      font-size: 22px;
      font-weight: 700;
      position: relative;
      margin-bottom: 30px;

      &:before {
        content: '';
        border-bottom: 1px solid $white;
        width: 60px;
        position: absolute;
        left: 0;
        bottom: -15px;
      }
    }

    .navbar-nav {
      .nav-link {
        color: $white;
        padding: 5px 0;

        &:hover {
          color: $yellow1;
        }
      }
    }

    .sos-med {
      a {
        display: inline-block;
        width: 35px;
        height: 35px;
        line-height: 35px;
        border-radius: 100%;
        background: $white;
        color: $blue;
        font-size: 20px;
        text-align: center;
        margin-right: 15px;

        &:hover {
          background: $yellow1;
          color: $blue;
        }
      }
    }
  }

  .bottom {
    text-align: center;
    font-size: 14px;
    padding: 15px;
    border-top: solid 1px rgba(151, 151, 151, 0.64);

    p {
      margin-bottom: 0;
    }
  }
}

main {
  .section-title {
    border-bottom: solid 1px #d8d8d8;
    margin-bottom: 30px;
    padding-bottom: 15px;
  }

  section {
    .MuiAppBar-colorPrimary {
      margin-top: 30px;
      background: transparent;
      border: none;
      box-shadow: none;
      border-top: solid 1px #e7e7e7;
      border-bottom: solid 1px #e7e7e7;

      @include media-breakpoint-up(md) {
        margin-top: 50px;
      }

      .MuiTab-root {
        color: $black1;
        font-weight: 700;
        font-family: $font-global;
        font-size: 18px;

        @include media-breakpoint-up(md) {
          padding: 20px;
        }
      }

      span[class*="PrivateTabIndicator-colorSecondary"] {
        background-color: $blue;
        height: 3px;
      }
    }

    .tab-content {
      padding-top: 15px;
      padding-bottom: 15px;

      @include media-breakpoint-up(md) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
  }
}