//sett color variable and fonts here
$black            : #000000;
$black-dark       : #353535;
$black1           : #252525;
$black2           : #434343;
$white            : #ffffff;
$grey             : #747474;
$grey1            : #838383;
$grey2            : #6b6b6b;
$blue             : #2a5b70;
$blue1             : #1b4557;
$blue-dark        : #465690;
$green            : #367f9e;
$green1           : #009688;
$warm-pink        : #f25767;
$red              : #cf5151;
$blue-light       : #e3f6ff;
$pale-grey        : #f4f5f7;
$grey             : #c1c6d0;
$yellow           : #eba959;
$yellow1           : #ffcc00;
$purple           : #7d4d99;

button {
  font-weight: 600;
  font-size: 16px;
  font-family: $font-global;

  &:focus {
    outline: none;
  }

  &.full {
    width: 100%;
  }

  &.btn-blue {
    border: none;
    background: $blue;
    color: $white;

    &:hover {
      background: $green;
      color: $white;
    }
  }

  &.btn-ongkir {
    background: $red;
    border: none;
    color: $white;
    font-weight: 700;
    font-size: 16px;
  }

  &.btn-yellow {
    background: $yellow1;
    color: $black1;

    &:hover {
      background: $yellow;
      color: $black1;
    }
  }

  &.btn-link {
    color: $blue;
    font-weight: 700;
    background: transparent;
    border: none;
  }

  &.btn-icon {
    background: transparent;
    border: none !important;
    outline: none !important;
    color: $black;
    font-size: 12px;
    font-weight: 700;
    padding: 0 14px;
    position: relative;

    .header-nav & {
      border-left:  solid 1px #c9ccd2 !important;

    }

    @include media-breakpoint-up(md) {
      font-size: 14px;
    }

    img {
      width: 20px;

      @include media-breakpoint-up(md) {
        width: 25px;

      }
    }

    .pop-red {
      background: $red;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      position: absolute;
      right: 9px;
      top: -3px;
    }

    &.text {
      display: flex;
      width: 96px;
      align-items: center;

      @include media-breakpoint-up(md) {
        width: 112px;
      }


      img {
        margin-right: 5px;
        width: 30px;

        @include media-breakpoint-up(md) {
          width: 35px;

        }
      }
    }

    &:hover {
      background: transparent;
      border: none;
      color: $black;
    }
  }

  &.MuiButton-root {
    outline: none !important;
    font-family: $font-global;
    text-transform: capitalize;
    padding: 10px 30px;
    border-radius: 0;

    @include media-breakpoint-up(lg) {
      padding: 12px 40px;
      font-size: 16px;
    }
  }

  &.MuiButton-containedPrimary {
    background-color: $blue;
    color: $white;
    border: 1px solid $blue;

    &:hover {
      background-color: $green;
    }
  }

  &.MuiButton-containedSecondary {
    background-color: $white;
    color: $black1;
    border: 1px solid $grey;

    &:hover {
      background-color: $pale-grey;
    }
  }

  &.btn-delete {
    border-radius: 5px;
    border: solid 1px #d7dbe4;
    color: $black1;
    font-weight: 400;
    font-size: 14px;
    padding: 8px 15px;
  }

  &.btn-star-product,
  &.btn-save-attr,
  &.btn-delete-product {
    border-radius: 100%;
    background-color: $pale-grey;
    border: none;
    position: absolute;
    bottom: 5px;
    padding: 5px;
    font-size: 12px;
    width: 26px;
    height: 26px;
  }

  &.btn-star-product {
    right: 52px;

    &:hover {
      color: $yellow;
    }
  }

  &.btn-delete-product {
    right: 20px;

    &:hover {
      color: $red;
    }
  }
  &.btn-delete-attr,
  &.btn-save-attr {
    border-radius: 100%;
    color: $white;
    border: none;
    position: absolute;
    bottom: 5px;
    padding: 5px;
    font-size: 20px;
    width: 40px;
    height: 40px;

  }

  &.btn-delete-attr {
    background-color: $red;
    right: 20px;

    &:hover {
      background-color: $black-dark;
    }
  }

  &.btn-save-attr {
    background-color: $green1;
    right: 70px;

    &:hover {
      background-color: $black-dark;
    }
  }

  &.btn-redeem {
    background: transparent;
    border: 1px solid $blue;
    padding: 10px 20px;
    color: $black1;
    width: 100%;
    margin-bottom: 15px;
    text-align: left;
    position: relative;

    i {
      color: $blue;
      margin-right: 15px;

      &.valid {
        position: absolute;
        right: 0;
        top: 15px;
        color: $green1;
      }
    }
  }

  .MuiButton-label {
    font-weight: 700;
  }
}

.text {
  &-yellow {
    color: #ff902b;
  }

  &-blue {
    color: $blue;
  }

  &-red {
    color: $warm-pink;
  }

  &-danger {
    font-size: 14px;
    font-weight: 700;
  }
}

.form-group {
  label {
    display: block;
  }

  .css-2613qy-menu {
    position: relative;
    z-index: 1000;
  }
}

.MuiFormControl-root {

  &.full {
    width: 100%;
  }

  .MuiOutlinedInput-root {
    border-radius: 0;

    .MuiOutlinedInput-input {
      font-family: $font-global;
      height: 20px;
      padding: 12px 10px;
      font-size: 14px;
    }
  }

  label.MuiInputLabel-outlined {
    transform: translate(14px, 15px) scale(1);
    font-family: $font-global;
    font-size: 14px;

    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }

  .MuiIconButton-root {
    font-size: 16px;
  }
}

.Toastify__toast {
  border-radius: 10px;
  min-height: 50px;
  font-family: $font-global;

  &.Toastify__toast--error {
    background: $red;
  }

  &.Toastify__toast--success {
    background: $green1;
  }
}

i {
  &.icon-p {
    background-color: $blue;
    width: 19px;
    height: 19px;
    font-style: normal;
    color: $white;
    font-size: 11px;
    display: inline-block;
    text-align: center;
    line-height: 19px;
    border-radius: 100%;
    position: relative;
    top: -2px;
  }
}

.label-cat {
  //background-color: $yellow;
  font-weight: 700;
  font-size: 12px;
  display: block;
}

.label-star {
  color: $yellow;
  font-size: 20px;
  position: absolute;
  left: 20px;
  top: 5px;
}


.card-product {
  border-radius: 10px;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14);
  overflow: hidden;
  width: 225px;
  margin-bottom: 20px;
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    width: 240px;
    margin-bottom: 30px;
  }

  .card-img {
    position: relative;
    width: 100%;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      height: 240px;
      width: 240px;
    }

    img {
      transition: all ease-in-out 0.3s;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    label {
      position: absolute;
      background: #e95c5c;
      padding: 5px;
      font-size: 10px;
      top: 10px;
      left: 10px;
      color: $white;
      z-index: 10;
    }
  }

  .card-body {
    text-align: center;
    padding: 15px 15px 0;
    border-top: solid 0.5px $grey;

    small {
      color: #6f6e6e;
      text-transform: uppercase;
      font-size: 10px;
    }

    h4, a {
      font-weight: 600;
      color: $black;
      font-size: 16px;
      min-height: 40px;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }
    }

    .price {
      min-height: 50px;
    }
  }

  p {
    color: #ff902b;
    font-size: 16px;
    font-weight: 700;
  }

  .btn-bottom {
    margin-top: 15px;
    background: $blue;
    color: $white;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;

  }
}

.discount-price {
  font-size: 12px;
  color: $black !important;
  margin-bottom: 0;
  text-decoration: line-through;
}

.star-wrap {
  .star-icon {
    font-size: 15px;
    margin: 0 5px;
    color: $grey;

    &.yes {
      color: $yellow1;
    }
  }
}

.btn-group {
  display: flex;
  justify-content: center;

  input {
    width: 60px;
    text-align: center;
    border: none;
    border-bottom: solid 0.5px #dcdcdc;

    &:focus {
      outline: none;
    }
  }

  button {
    border: none;
    background: transparent;
    border-radius: 100%;
    color: #258f7b;
    font-size: 25px;

    &:disabled {
      color: $grey1;
    }
  }
}

.post-wrap {
  display: flex;
  padding: 15px;

  .img-post {
    min-width: 120px;
    max-width: 120px;
    margin-right: 15px;
  }

  h3 {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
    margin-bottom: 5px;
  }

  a {
    color: $blue;
    font-weight: 700;
    text-decoration: underline;
  }
}

form {
  .form-group {
    > label {
      font-weight: 700;
      font-size: 16px;
    }

    .label-form {
      font-weight: 700;
      font-size: 16px;
      display: block;
    }

    small {
      font-size: 10px;
      line-height: 17px;
      color: #6d6d6d;
      display: block;
    }
  }

  .btn-rate {
    .star-icon {
      font-size: 25px;
      margin-right: 10px;
    }
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: $blue;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: $blue1;
  }

  .MuiTypography-body1 {
    font-weight: 700;
    font-family: $font-global;
  }
}

i {
  &.icon-point {
    background: $blue;
    text-align: center;
    color: $white !important;
    font-size: 10px;
    line-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    font-style: normal;
    display: inline-block;
  }
}

iframe {
  max-width: 100%;
}

.ge-dialog {
  &.order {
    text-align: left;

    .MuiDialog-paper {
      .MuiDialogContent-root {
        padding: 15px;
      }

      .history-list {
        background-color: $blue-light;
        border-radius: 15px;
        padding: 10px;
        margin: 10px 0;

        p {
          font-size: 14px;
          margin-bottom: 0;
        }

        small {
          font-weight: 700;
        }
      }

      h3 {
        font-family: $font-global;
        font-weight: 700;
        font-size: 16px;
        border-bottom: 1px solid $grey;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }

      label {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
        color: $grey1;
      }

      p {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }

  }

  .MuiDialog-paper {
  border-radius: 15px !important;


    h2 {
      font-family: $font-global;
      font-weight: 700;
      text-align: center;
      font-size: 18px;

      @include media-breakpoint-up(lg) {
        font-size: 22px;
      }
    }

  button {
    font-weight: 700;
    width: 100%;
    padding: 10px;
  }
  .MuiDialogTitle-root {
    @include media-breakpoint-up(lg) {
      padding: 50px 50px 15px;
    }

    @include media-breakpoint-up(xl) {
      padding: 65px 65px 15px;
    }
  }

  .MuiDialogContent-root {
    padding: 0 50px;

    @include media-breakpoint-up(lg) {
      padding: 15px 50px 30px;
    }

    @include media-breakpoint-up(xl) {
      padding: 15px 65px 30px;
    }
  }

}
}


.MuiIconButton-colorSecondary {
  color: $blue !important;

  &.Mui-checked {
    color: $blue !important;

  }
}

.radio-option {
  display: flex;
  align-items: center;
  min-width: 300px;
  border: solid 1px #c7c7c7;
  margin: 0 0 15px;
  font-family: $font-global;
  padding: 15px;

  span {
    font-weight: 700;
    margin-left: 10px;
  }

  strong {
    margin-left: auto;
  }
}

.MuiFormGroup-root {

  .radio-option {
    display: flex;
    justify-content: space-between;
    min-width: 300px;
  }
  .MuiFormControlLabel-root {
    border: solid 1px #c7c7c7;
    margin: 0 0 15px;
    font-family: $font-global;
    padding: 10px 15px 10px 0;

    .MuiIconButton-root {
      margin-right: 20px;
    }
  }

  .MuiTypography-root {
    width: 100%;
  }
}

.MuiTable-root {
  .MuiTableHead-root {
    background-color: $blue;

    .MuiTableCell-head {
      color: $white;
      font-weight: 600;
    }
  }

  .MuiTableRow-root {
    &.true {
        td {
          color: #ff5252;
        }
      }
  }
  .MuiTableCell-root {
    font-family: $font-global;
    padding: 15px 10px;
    text-align: center;
  }
}

.ReactModal__Overlay {
  z-index: 1002;
}

.label-status {
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 25px;
  display: inline-block;

  &.pending {
    background: $grey;
  }

  &.processing {
    background: $yellow;
  }

  &.shipped {
    background: $purple;
    color: $white;
  }

  &.completed {
    background: $green;
    color: $white
  }
  &.complete {
    background: $green;
    color: $white
  }

  &.cancelled {
    background: $warm-pink;
    color: $white
  }
}