@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/dist/ReactToastify.min.css";
@import "~react-circular-progressbar/dist/styles.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '~react-image-lightbox/style.css';

@import "styles/fonts.scss";
@import "styles/variables.scss";
@import "styles/layout";
@import "styles/pages";

html body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  font-family: $font-global;
}

a,
button {
  transition: all ease-in-out 0.3s;
  color: $blue;

  &:hover {
    text-decoration: none;
  }

  &.underline {
    text-decoration: underline;
  }
}

img {
  max-width: 100%;

  &.loading {
    width: 100px;
  }

  &.user-photo {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
  }

  &.img-user {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
  }

  &.vessel-photo {
    width: 360px;
  }

  &.img-bank {
    width: 150px;
  }

  &.img-product {
    width: 80px;
  }

}

h1 {
  font-size: 24px;
  font-weight: 700;

  date {
    color: $grey;
    font-size: 14px;
    border-left: solid 1px #dedada;
    margin-left: 10px;
    padding-left: 10px;
  }
}

h2 {
  font-weight: 700;
  font-size: 20px;

  @include media-breakpoint-up(md) {
    font-size: 24px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;

  @include media-breakpoint-up(md) {
    font-size: 17px;
  }
}

p {
  &.big-num {
    font-weight: 700;
    font-size: 18px;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }

  &.p-small {
    font-size: 10px;

    @include media-breakpoint-up(md) {
      font-size: 14px;
    }
  }
}
.alert {
  background: #ffcc66 ;
  font-weight: 700;
  margin-bottom: 0;
  border: 1px solid transparent ;
  border-radius: 0.25rem ;

  p {
    text-align: center;
    font-size: 14px !important;
    color: $black;
  }

  &.alert-info {
    background: #3e98c7;
    p {
      text-align: left;
      font-size: 14px !important;
      color: white;
    }
  }
}

.alert.alert-poin {
  background: #ffcc66 !important;
  font-weight: 700;
  margin-bottom: 0;
  border: 1px solid transparent !important;
  border-radius: 0.25rem !important;;

  p {
    text-align: center;
    font-size: 14px !important;
    color: $black;
  }
}
/*

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $white-three;
  border-radius: 10px;
  outline: 1px solid $white-three;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}*/

.ReactModal__Overlay {
  z-index: 10000 !important;
}