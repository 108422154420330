.home {
  .popup-banner {
    position: fixed;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 100000;
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.9);
    padding-top: 20vh;

    img {
      width: 800px;
      max-width: 90%;
    }
  }

  .slick-slider {
    overflow: hidden;
  }

  .section-banner {
    padding-top: 30px;
    padding-bottom: 20px;

    @include media-breakpoint-up(md) {
      padding-top: 45px;
    }

    form {
      box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.4);
      padding: 35px 15px;
      min-height: 454px;

      h3 {
        font-weight: 800;
        font-size: 22px;
        text-align: center;
        margin-bottom: 30px;
      }

      .MuiInputBase-fullWidth {
        background: $white;
      }

      p,a {
        color: $black;
        font-size: 12px;
      }

      button.btn {
        width: 100%;
        font-weight: 700;
        font-size: 15px;
        margin: 5px 0;
      }

      .text-forgot {
        color: $red;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .dash-profile {
      box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.4);
      padding: 35px 15px;
      min-height: 454px;
      text-align: center;

      h3 {
        font-weight: 800;
        font-size: 22px;
        text-align: center;
        margin-bottom: 0;
      }

      p {
        margin-bottom: 5px;
      }

      .img-profile {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 100%;
        margin: 0 auto 15px;
      }
    }

    .slick-slide img {
      width: 730px;
     /* height: 450px;*/
      object-fit: cover;
    }

    .slick-dots {
      position: relative;
      top: -45px;
      text-align: right;

      .slick-active {
        width: 40px;

        button {
          width: 40px;
          border-radius: 25px;
        }
      }

      button {
        transition: all ease-in-out 0.3s;
        background: rgba(255,255,255,0.8);
        width: 15px;
        height: 15px;
        border-radius: 100%;

        &:before {
          display: none;
        }
      }
    }
  }

  .section-menu {
    .card-menu {
      position: relative;
      padding: 25px 15px;
      margin-bottom: 20px;
      cursor: pointer;
      min-height: 110px;


      &:hover {
        p {
          font-size: 27px;

          @include media-breakpoint-up(lg) {
            font-size: 30px;
          }
        }
      }

      label {
        font-weight: 900;
        position: absolute;
        color: $blue1;
        right: 15px;
        top: 10px;
      }

      p {
        font-weight: 900;
        color: $blue1;
        font-size: 26px;
        transition: all ease-in-out 0.3s;
        margin-bottom: 0;

        span {
          color: $white;
        }
      }
    }
  }

  .section-product {
    padding-top: 30px;
    padding-bottom: 30px;

    .item-slider {
      padding: 0 15px;
    }

    .slick-prev {
      left: 15px;
      z-index: 1;

      @include media-breakpoint-up(md) {
        left: -25px;
      }
    }

    .slick-next {
      right: 0;

      @include media-breakpoint-up(md) {
        right: -35px;
      }
    }

    .slick-slider {
      max-height: 500px;
    }

    .slick-dots {
      position: relative;
      top: -15px;
      text-align: center;

      .slick-active {
        width: 40px;

        button {
          width: 40px;
          border-radius: 25px;
        }
      }

      button {
        transition: all ease-in-out 0.3s;
        background: $blue;
        width: 15px;
        height: 15px;
        border-radius: 100%;

        &:before {
          display: none;
        }
      }
    }
  }

  .section-quiz {
    padding-top: 15px;
    padding-bottom: 30px;

    .card-quiz {
      background-image: url("../images/card.svg");
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: cover;
      display: flex;
      padding: 15px 30px 0;
      height: 180px;
      color: $white;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        p {
          color: $yellow1;
        }
      }

      img {
        height: 120px;
        margin-right: 30px;
      }

      p {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 0;
        line-height: 30px;
        transition: all ease-in-out 0.3s;

      }
    }
  }

  .section-video {
    margin-bottom: 30px;

    .img-video {
      cursor: pointer;
      position: relative;
      margin-bottom: 15px;

      .fa-play-circle {
        color: $white;
        position: absolute;
        font-size: 65px;
        left: 38%;
        top: 33%;
        pointer-events: none;

        @include media-breakpoint-up(lg) {
          font-size: 90px;
          left: 40%;
          top: 35%;
        }
      }
    }
  }

  .section-gallery {
    padding-bottom: 30px;

    @include media-breakpoint-up(md) {
      padding-bottom: 50px;
    }

    .slick-prev.custom-left {
      left: -30px;

      &::before {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        background-image: url("../images/left.svg");
      }
    }

    .slick-next.custom-right {
      right: -20px;

      &::before {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        background-image: url("../images/right.svg");
      }
    }
  }

  iframe {
    max-width: 80%;
  }

  .youtubeModal__modalContainer___1eppj {
    z-index: 5000;
  }
}

main.products {
  min-height: 700px;

  .tag-options {
    .tags {
      color: $white;
      background: $blue;
      border: 1px solid $blue;
      padding: 3px 10px;
      margin-right: 5px;
      border-radius: 25px;
      font-size: 14px;
    }
  }

  .tags-item {
    list-style: none;
    font-size: 14px;
    display: flex;
    padding: 0;

    li {
      color: $blue;
      border: 1px solid $blue;
      padding: 5px 10px;
      margin-right: 5px;
      cursor: pointer;

      &.active {
        color: $white;
        background: $blue;
      }
    }
  }

  .filter-title {
    border-left: 3px solid $blue;
    padding-left: 10px;
    margin-bottom: 20px;

    h2 {
      font-size: 20px;

      @include media-breakpoint-up(md) {
        font-size: 22px;
      }
    }
  }

  .sort-filter {
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: solid 1px #e7e7e7;
    border-bottom: solid 1px #e7e7e7;
    font-size: 14px;
  }

  .search-filter {
    position: relative;

    input {
      border-radius: 0;
      border: solid 1px #d8d8d8;
      font-size: 16px;
      height: 38px;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      background: #e2e2e2;
      border: none;
      border-radius: 0;
      color: $blue;
      font-size: 15px;
      height: 38px;

      &:hover,
      &:focus {
        background: $blue;
        outline: none;
        color: $white;
      }
    }
  }

  .tags-filter {
    .tags {
      padding: 0;

      li {
        list-style: none;
        font-size: 12px;
        color: $blue;
        border: 1px solid $blue;
        display: inline-block;
        padding: 10px 15px;
        margin-right: 10px;
        cursor: pointer;
        margin-bottom: 10px;

        &.active {
          background: $blue;
          color: $white;
        }
      }
    }
  }

  .section-products {
    padding-top: 30px;
    padding-bottom: 30px;

    @include media-breakpoint-up(md) {
      padding-top: 45px;
      padding-bottom: 45px;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 75px;
    }

    .slick-slider {
      @include media-breakpoint-up(md) {
        max-height: 240px;
      }

      @include media-breakpoint-up(lg) {
        max-height: 330px;
      }

      @include media-breakpoint-up(xl) {
        max-height: 400px;
      }
    }

    .btn-rate {
      .star-icon {
        i {
          color: $grey;
          transition: all ease-in-out 0.3s;
        }

        &.yes {
          i {
            color: $yellow1;
          }
        }
      }
    }
  }

  .review-wrapper {
    .item-review {
      border-bottom: solid 1px #e7e7e7;
      display: flex;
      padding-top: 15px;

      .img-wrap {
        width: 100px;
        margin-right: 15px;

        img {
          width: 100px;
          height: 100px;
          object-fit: contain;
        }

        &.product {
          width: 120px;
        }
      }

      h3 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0;
      }

      time,
      date {
        display: block;
        font-size: 14px;
        color: #9c9c9c;
      }

       p {
         font-size: 14px;
       }

      .btn-reload {
        color: $blue;
        font-size: 15px;
      }

      .btn-delete {
        color: $red;
      }
    }
  }


  p.description {
    white-space: pre-wrap;
  }

  .box-cart {
    border: solid 1px #505050;
    padding: 15px;

    @include media-breakpoint-up(xl) {
      padding: 25px;
    }

    .alert {
      background: $warm-pink;
      border-radius: 10px;
      padding: 8px;
      font-size: 12px;
      color: white;

      p {
        font-size: 10px;
        margin-bottom: 0;
      }
    }
  }

  .label-tnc {
    .MuiTypography-root {
      font-size: 12px;
    }
  }

  .cart-detail {
    padding: 15px;
    border: solid 1px #c7c7c7;

    @include media-breakpoint-up(lg) {
      padding: 20px;
    }

    h2 {
      font-size: 18px;

    }
  }
}

.accounts {
  .section-account {
    padding-top: 30px;
    padding-bottom: 30px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 50px;
    }

    .label-status {
      cursor: pointer;
    }

    .profile-img {
      width: 100px;
      margin: auto;
      position: relative;
      z-index: 1;

      @include media-breakpoint-up(lg) {
        width: 170px;
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 100%;

        @include media-breakpoint-up(lg) {
          width: 170px;
          height: 170px;
        }
      }

      .avatar {
        display: none;
      }

      .label-file {
        position: absolute;
        background: $blue;
        color: $white;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        text-align: center;
        right: 10px;
        top: 75%;
        visibility: hidden;
        opacity: 0;
        cursor: pointer;
        transition: all ease-in-out 0.3s;


        i {
          line-height: 30px;
        }
      }

      &:hover {
        .label-file {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .account-wrap {
      margin-top: -50px;
      padding-top: 65px;
      border: solid 1px #d0d0d0;
      position: relative;

      @include media-breakpoint-up(lg) {
        margin-top: -85px;
        padding-top: 100px;
      }

      .button-logout {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        background: transparent;
      }

      .MuiAppBar-colorPrimary {
        .MuiTab-root {
          font-size: 14px;
          padding: 5px;
          text-transform: capitalize;
          letter-spacing: 0;

          &.Mui-selected {
            background: $blue;
            color: $white;
          }
        }
      }

      .tab-content {
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-up(lg) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}

.register {
  padding-bottom: 30px;
}

.zakat {
  padding-top: 30px;
  padding-bottom: 30px;

  @include media-breakpoint-up(md) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .info-bank {
    h4 {
      font-weight: 700;
      color: $blue;
      margin-bottom: 15px;
    }

    p.bold {
      color: $green1;
      font-weight: 900;

    }


    h5 {
      font-weight: 700;
      font-size: 20px;
      color: $blue;
      margin-bottom: 0;
    }
  }

  .profile-img {
    .img-profile {
      margin-bottom: 15px;
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  .box-zakat {
    border: 1px solid $grey;
    padding: 15px;
  }

  .card-bubble {
    border: 2px dashed $blue;
    border-radius: 15px;
    padding: 15px;

    i {
      color: $blue;
      font-size: 20px;
    }

    strong {
      font-size: 24px;
      color: $blue;
    }
  }

  .icon {
    margin-right: 10px;
    background: $grey;
    border-radius: 100%;
    padding: 5px;

    &.fa-arrow-left {
      color: $red;
    }

    &.fa-arrow-right {
      color: $green1;
    }
  }

  .btn-icon {
    font-size: 14px;
    color: $red;
  }
}

.pages {
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 400px;

  @include media-breakpoint-up(md) {
    padding-top: 45px;
    padding-bottom: 45px;
    min-height: 430px;
  }

  h1 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      font-size: 32px;
    }

  }

  h2 {
    font-size: 26px;
    font-weight: 700;

    @include media-breakpoint-up(md) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 22px;
    font-weight: 700;

    @include media-breakpoint-up(md) {
      font-size: 26px;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 700;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }

  .loading-page {
    .fa {
      font-size: 30px;
    }
  }

  .btn-blue {
    padding: 5px 20px;
    background: $blue;
    color: $white;
  }

  .category {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $blue;
  }

  time {
    display: block;
    text-align: center;
    color: $black2;
    margin-bottom: 30px;
  }

  img {
    margin-bottom: 30px;
  }

  .table-responsive {
    max-width: 100%;
    overflow-x: auto;

    .table-bordered {
      &.small-text {
        font-size: 14px;
      }

      tr {
        &.blue {
          td {
            background: $blue;
            color: $white;
          }
        }

        &.yellow {
          td {
            background: $yellow1;
          }
        }

        .xsmall span, span.xsmall {
          font-size: 12px;
          color: $warm-pink;
        }
      }
    }
  }

  &.simulation {

    h3 {
      font-size: 18px;
      font-weight: 900;
    }
    .card {
      padding: 25px 25px;
    }
    .form-group {
      label {
        font-size: 14px;
      }

      .MuiFormControl-root {
        &.yellow {
          background: $yellow1;
          border: $yellow1;
        }
      }

      .MuiOutlinedInput-adornedEnd {
        padding-right: 0;
      }

      .MuiInputAdornment-positionEnd {
        .MuiTypography-body1 {
          padding: 0 10px;
          line-height: 3.7;
          background: $grey;
          font-size: 12px;
          color: $black;
        }
      }


    }
  }

  &.about {
    padding-top: 0;
    margin-top: -15px;

    section {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .banner-about {
      height: 300px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 30px;
    }

    h1, h2 {
      color: $blue;
    }

    h3 {
      font-size: 22px;
    }
  }

}

.blogs {
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 400px;

  @include media-breakpoint-up(lg) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  article {
    border: solid 1px #eaeaea;
    margin-bottom: 15px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 30px;
    }

    .img-fluid {
      width: 100%;
    }

    .content-article {
      padding: 15px;
    }
  }

  time {
    display: block;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 15px;

    .cat {
      color: $blue;
      text-transform: uppercase;
      margin-right: 10px;
    }

    .date {
      margin-left: 10px;
      color: $grey;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }

  .btn-more {
    color: $grey2;
    border: 1px solid $grey2;
    background: transparent;
    font-size: 12px;
    padding: 10px 25px;
    text-transform: uppercase;
    font-weight: 400;

    i {
      margin-left: 8px;
    }

    &:hover {
      color: $white;
      border: 1px solid $blue;
      background: $blue;
    }

  }
}